<template>
    <div>
        <card-header title="Edit Trip" icon="fa-pencil"/>

        <card-body>
            <trip-form :trip="trip"/>
            <card-list>
                <subheader-list-item title="Terms PDF" icon="fa-file-pdf"/>
                <data-row title="Terms PDF"><i class="fas mr-2" :class="{'fa-ban has-text-warning': !trip.terms, 'fa-check has-text-primary': trip.terms}"></i>{{trip.terms_filename}}</data-row>
                <container-list-item padded>
                    <file-pond
                        name="file"
                        ref="pond"
                        label-idle="Drop or click to add PDF..."
                        :allow-multiple="false"
                        :allowRevert="false"
                        accepted-file-types="application/pdf"
                        :files="files"
                        @init="handleFilePondInit"
                        @processfile="handleFilePondProcess"
                        :server="{
                            url: apiUrl,
                            timeout: 7000,
                            process: {
                                url: 'api/trips/terms',
                                method: 'POST',
                                headers: {
                                    'Authorization': token
                                },
                                withCredentials: false
                            }
                        }"
                    />
                </container-list-item>
            </card-list>
        </card-body>

        <card-footer class="pt-3 pb-3">
            <div class="columns">
                <div class="column">
                    <b-button type="is-warning is-outlined" size="is-small" @click="deleteTrip"><i class="far fa-trash-alt"></i><span class="ml-2" v-if="confirmDelete">Delete Trip?</span></b-button>
                </div>
                <div class="column has-text-right">
                    <b-button type="is-primary" size="is-small" :disabled="!trip.title || !trip.location || !trip.start_on || !trip.end_on" @click="submit"><i class="fas fa-check mr-3"></i>Save Trip</b-button>
                </div>
            </div>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from '../../TIER/components/CardHeader';
    import CardFooter from "../../TIER/components/CardFooter";
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import CardBody from "../../TIER/components/CardBody";
    import ContainerListItem from "../../TIER/components/ContainerListItem";

    import TripForm from "@/cards/trips/TripForm";

    import {mapGetters} from "vuex";
    import {client as http} from '../../http_client';

    // Import Vue FilePond
    import vueFilePond from 'vue-filepond';

    // Import FilePond styles
    import 'filepond/dist/filepond.min.css';

    // Import FilePond plugins
    // Please note that you need to install these plugins separately

    // Import image preview plugin styles
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

    // Import image preview and file type validation plugins
    import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
    import DataRow from "../../TIER/components/DataRow";

    // Create component
    const FilePond = vueFilePond(FilePondPluginFileValidateType);

    export default {
        props: ['card', 'props'],
        components: {DataRow, TripForm, ContainerListItem, CardBody, SubheaderListItem, CardList, CardFooter, CardHeader, FilePond},
        data: function () {
            return {
                trip: {},
                dirty: null,
                confirmDelete: false,
                files: [],
                apiUrl: (location.hostname === 'localhost') ? 'http://localhost:3000/' : 'https://portal.tennisventures.net:3000/'
            };
        },
        computed: {
            ...mapGetters(['token']),
        },
        methods: {
            loadTrip: function () {
                this.$emit('loading', true);
                http.get('/api/trips/' + this.props.tripId, {force: true}).then(response => {
                    this.trip = response.data;
                    this.$emit('loading', false);
                });
            },
            submit() {
                this.$emit('loading', true);
                http.put('/api/trips/' + this.trip.id, this.trip, {force: true}).then(response => {
                    this.$reloadCard('trips');
                    this.$reloadCard('trip', {tripId: this.props.tripId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            },
            deleteTrip() {
                if (this.confirmDelete) {
                    this.$emit('loading', true);
                    http.delete('/api/trips/' + this.props.tripId).then(response => {
                        this.$reloadCard('trips', {});
                        this.$saveAlert(false);
                        this.$closeCard(this.card);
                    }).catch(err => {

                    });
                } else {
                    this.confirmDelete = true;
                    setTimeout(() => {
                        this.confirmDelete = false;
                    }, 3000);
                }
            },
            handleFilePondInit: function() {
                // console.log('FilePond has initialized');
                // FilePond instance methods are available on `this.$refs.pond`
            },

            handleFilePondProcess: function(err, response) {
                // Todo this doesn't handle errors
                // FilePond:processfile - Finished processing a file, if the detail object contains an error property, something went wrong

                let file = JSON.parse(response.serverId);

                this.trip.terms = file.file_name;
                this.trip.terms_filename = file.file_original_name;

                setTimeout(function() {
                    this.$refs.pond.removeFile(file.id);
                }.bind(this), 3000);
            },
        },
        watch: {
            'props.tripId': function () {
                this.dirty = null;
                this.loadTrip();
            },
            'trip': {
                deep: true,
                handler: function () {
                    this.dirty = (this.dirty !== null);
                    if (!this.$TIER.saveAlert.active && this.dirty)
                        this.$saveAlert(true, 'Are you done editing this trip? Unsaved changes will be lost.');
                }
            }
        },
        mounted() {
            this.loadTrip();
        }
    };
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('card-header',{attrs:{"title":"Edit Trip","icon":"fa-pencil"}}),_c('card-body',[_c('trip-form',{attrs:{"trip":_vm.trip}}),_c('card-list',[_c('subheader-list-item',{attrs:{"title":"Terms PDF","icon":"fa-file-pdf"}}),_c('data-row',{attrs:{"title":"Terms PDF"}},[_c('i',{staticClass:"fas mr-2",class:{'fa-ban has-text-warning': !_vm.trip.terms, 'fa-check has-text-primary': _vm.trip.terms}}),_vm._v(_vm._s(_vm.trip.terms_filename))]),_c('container-list-item',{attrs:{"padded":""}},[_c('file-pond',{ref:"pond",attrs:{"name":"file","label-idle":"Drop or click to add PDF...","allow-multiple":false,"allowRevert":false,"accepted-file-types":"application/pdf","files":_vm.files,"server":{
                        url: _vm.apiUrl,
                        timeout: 7000,
                        process: {
                            url: 'api/trips/terms',
                            method: 'POST',
                            headers: {
                                'Authorization': _vm.token
                            },
                            withCredentials: false
                        }
                    }},on:{"init":_vm.handleFilePondInit,"processfile":_vm.handleFilePondProcess}})],1)],1)],1),_c('card-footer',{staticClass:"pt-3 pb-3"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-button',{attrs:{"type":"is-warning is-outlined","size":"is-small"},on:{"click":_vm.deleteTrip}},[_c('i',{staticClass:"far fa-trash-alt"}),(_vm.confirmDelete)?_c('span',{staticClass:"ml-2"},[_vm._v("Delete Trip?")]):_vm._e()])],1),_c('div',{staticClass:"column has-text-right"},[_c('b-button',{attrs:{"type":"is-primary","size":"is-small","disabled":!_vm.trip.title || !_vm.trip.location || !_vm.trip.start_on || !_vm.trip.end_on},on:{"click":_vm.submit}},[_c('i',{staticClass:"fas fa-check mr-3"}),_vm._v("Save Trip")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }